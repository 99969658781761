import ajaxSubmit from './ajaxSubmit';
import ajaxForm from './ajaxForm';
import disableModalTriggers from './disableModalTriggers';
import remoteContent from './remoteContent';


$.fn.ajaxForm = ajaxForm;
$.fn.ajaxSubmit = ajaxSubmit;
$.fn.remoteContent = remoteContent;

$(function() {
    $(".js-ajaxFormButton").ajaxForm();
    $(".js-remoteContent").remoteContent();

    $(document).on('click', '.js-remoteContent', function() {
        if ($(this).data('remoteContent') === undefined) {
            $(this).remoteContent();
            $(this).trigger('click');
        }
    })

    disableModalTriggers();
});