import 'jquery-ui/ui/widgets/datepicker';

require('./style.scss');

function isTypeSupported(type) {
    var input = document.createElement("input");
    input.setAttribute("type", type);

    const supported = input.type === type;
    input.remove();
    return supported;
}

$(function() {
    try {
        require('jquery-ui/ui/i18n/datepicker-' + document.documentElement.lang)
        $.datepicker.setDefaults($.datepicker.regional[document.documentElement.lang]);
    } catch (e) {
        console.warn('Unable to load locale');
    }

    let elements;
    if (isTypeSupported("date")) {
        elements = $("[data-jquery-datepicker]");
    } else {
        console.log('The date type was not supported, initializing the jquery date picker');
        elements = $("input[type='date'], [data-jquery-datepicker]");
    }

    elements.each(function() {
        const format = $(this).data('jquery-date-format') || 'yy-mm-dd';
        $(this).attr('type', 'text');
        $(this).datepicker({
            dateFormat: format
        })
    })
})