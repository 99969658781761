import '../../modules/jquery';
import '../../modules/bootstrap';
import '../../modules/font-awesome';
import '../../modules/fancybox';
import '../../modules/jquery-confirm';
import '../../modules/jquery-datepicker';
import '../../modules/jquery-ui-effects';
import '../../modules/toastr';
import '../../modules/ajax-form';
import '../../modules/batch-form';
import '../../modules/select2';
import "../../modules/sidebar";
import "../../modules/auto-resize";
import "../../modules/preserve-modal-content";
import "../../modules/tokenify";
import "../../modules/polyfill";
import "../../modules/popper";
import "../../modules/json-editor";
import { init as initStickyTop } from './lib/stickyTop';
import { multiple_modal } from "./lib/stacked-modals";
import { init as initZendeskArticles } from './lib/zendesk';
import { init as initPreventRoboto } from './lib/preventRoboto';
import { init as initSubmenu } from './lib/submenu';
import { init as initUriContent } from './lib/uri-content';
import { init as initAutoResize } from './lib/auto-resize';
import { init as initHorizontalScrollingShadows } from './lib/horizontal-scrolling-shadows';

$("[data-toggle='popover']").popover();
$("[data-toggle='tooltip']").tooltip();

$("input").on('change keyup keydown', function (e) {
    $(this).closest(".has-error").removeClass("has-error");
    $(this).siblings(".help-block").remove();
});

initPreventRoboto();

$(function () {
    $(document).on('submit', "form:not([data-action='live#action'],[data-action='live#action:prevent'])", function (e) {
        if ($(this).hasClass('is-submitting')) {
            e.preventDefault();
            console.warn('Prevented double submit');
            return;
        }
        $(this).addClass('is-submitting');
        $(this).find(':submit').addClass('btn-disabled disabled');
    });

    $(".dropdown-submenu a[data-toggle='dropdown']").on('click', function (e) {
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
    });

    $(".is-invalid").on('change', function () {
        $(this).closest('.has-error').removeClass('has-error');
        $(this).removeClass('is-invalid');
    });

    $(document).on('hidden.bs.modal', '.modal', function () {
        $('.modal:visible').length && $(document.body).addClass('modal-open');
    });

    $(document).on('turbo:frame-load', function (e) {
        $('[data-toggle="popover"]', e.target).popover();
        $('[data-toggle="tooltip"]', e.target).tooltip();
        initStickyTop(e.target);
    });

    initStickyTop();

    initZendeskArticles();

    initSubmenu();

    initUriContent();

    initAutoResize();

    initHorizontalScrollingShadows();
});

global.insertParam    = function (url, key, value) {
    console.log(url, key, value);
    const parsedUrl = new URL(url);
    if (parsedUrl.searchParams.has(key)) {
        parsedUrl.searchParams.set(key, value);
    } else {
        parsedUrl.searchParams.append(key, value);
    }

    console.log(parsedUrl);

    return parsedUrl.toString();
}
global.insertParams   = function (url, map = {}) {
    for (const key in map) {
        if (!map.hasOwnProperty(key)) {
            continue;
        }
        url = insertParam(url, key, map[key]);
    }
    return url;
}

multiple_modal();