import 'webpack-jquery-ui/autocomplete';
import TokenField from "../../src/TokenField";

require('jquery-ui/themes/base/theme.css');

const tokenify = function (parent, options = {}) {
    const $inputs = $("input[type=text][data-source]:not(.token-ignore)", parent);
    $inputs.each(function () {
        const $this = $(this);
        if (!$this.data("loaded")) {
            const source = JSON.parse(this.dataset.source);

            const tokenfield = new TokenField(
                $this,
                undefined,
                {
                    ...options,
                    items    : source,
                    newItems : false,
                    minChars : 1,
                    itemLabel: 'label',
                    itemValue: 'value',
                    itemData : 'label'
                });
            // When creating or removing a token, try to find the same value.
            $this.on('tokenfield.removedtoken tokenfield.createdtoken', function (e) {
                let originalValue = [];

                if ($this[0].dataset.value) {
                    try {
                        originalValue = JSON.parse($this[0].dataset.value);
                    } catch (e) {
                        console.warn(e);
                    }
                }

                if ((typeof originalValue) === 'object') {
                    const j = [];
                    $.each(originalValue, function () {
                        j.push(this);
                    });
                    originalValue = j;
                }

                const newElements = [];
                const tokens      = tokenfield.getTokens();

                for (const token of tokens) {
                    // Try to find if the label exists with a value in the original value
                    const existingValue = originalValue.find((n) => n.value === token.value);

                    if (existingValue !== undefined) {
                        // There is an existing value
                        newElements.push(existingValue);
                        originalValue.splice(originalValue.indexOf(existingValue), 1);
                    } else {
                        // Try to find if there is original value with the same label
                        const matchedValue = originalValue.find((n) => n.label === token.label);
                        if (matchedValue !== undefined) {
                            newElements.push(matchedValue);
                            originalValue.splice(originalValue.indexOf(matchedValue), 1);
                        } else {
                            newElements.push(token);
                        }
                    }
                }
                tokenfield.setTokens(newElements);
            });

            $this.on('tokenfield.updatedtokens', function (e) {
                let value = $(this).val();

                if (typeof value === 'undefined' || value.length < 1) {
                    value = '[]';
                }
                tokenfield.setTokens(JSON.parse(value));
                tokenfield.update();
            });

            tokenfield.init();
            $(this).data('tokenfield', tokenfield);
            $(this).data("loaded", true);
        }
    })
};

$(document).ready(function () {
    tokenify(document);
});

$.fn.tokenfield = function (options = TokenField.defaultOptions) {
    tokenify(this, options);

    return this;
}