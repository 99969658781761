import React from 'react';
import Page from './Page';
import Headers from './Headers';

class Results extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            results: props.results
        };
    }

    onHeaderClick = (headerUsed) => {
        this.props.setHeaderUsed(headerUsed);
    };


    render() {
        const {results, onMouseOver, onMouseOut, noResultText} = this.props;
        let {headerUsed} = this.props;

        let page       = null;
        const headers  = results.headers;

        if (headerUsed === null && headers.length > 0) {
            headerUsed = headers[0];
        }

        if (results.results[headerUsed] != undefined) {
            page = <Page searches={results.searches} results={results.results[headerUsed].results} noResultText={noResultText}/>
        }

        return (
            <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <Headers headers={headers} used={headerUsed} onClick={this.onHeaderClick}/>
                <div className={"search_box-results-container"}>
                {page}
                </div>
            </div>
        );
    }
}

export default Results;