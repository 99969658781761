import React, {Component} from 'react';
import PageItem from "../PageItem";

class Page extends Component {

    createdRefs = [];

    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 0
        };

        for (let i = 0; i < this.props.results.length; i++) {
            this.createdRefs.push(React.createRef());
        }
    }

    mouseOver = (index) => {
        this.setState({
            currentIndex: index
        })
    };

    keyDown = (event) => {
        const resultLength = this.props.results.length;
        if (resultLength <= 1) {
            return;
        }


        let newIndex = this.state.currentIndex;
        switch (event.keyCode) {
            case 38: // up arrow
                if (this.state.currentIndex == 0) {
                    newIndex = resultLength - 1;
                } else {
                    newIndex--;
                }
                break;
            case 40: // down arrow
                if (this.state.currentIndex == resultLength - 1) {
                    newIndex = 0;
                } else {
                    newIndex++;
                }
                break;
            default:
                return;
        }

        if (this.state.currentIndex != newIndex && this.createdRefs[newIndex] !== undefined) {
            this.setState({
                currentIndex: newIndex
            });

            this.createdRefs[newIndex].current.scrollIntoView({
                behavior: 'smooth',
                block   : 'nearest'
            });
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this.keyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDown, false);
    }

    render() {
        const {results, noResultText, searches} = this.props;

        if (results.length == 0) {
            return <ul className="search-results">
                <li className="search-message">{noResultText}</li>
            </ul>;
        }
        const pages = results.map(({title, sub_title, icon, url, other_organization_name, title_of_location_risk_category}, index) => (
            <li ref={this.createdRefs[index]} key={index}>
                <PageItem
                    searches={searches}
                    keyIndex={index}
                    title={title}
                    sub_title={sub_title}
                    icon={icon}
                    url={url}
                    other_organization_name={other_organization_name}
                    title_of_location_risk_category={title_of_location_risk_category}
                    mouseOver={this.mouseOver}
                    active={this.state.currentIndex == index}
                />
            </li>
        ));

        return <ul className="search-results">{pages}</ul>;
    }
}

export default Page;