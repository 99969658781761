import toastr from 'toastr';

require('./style.scss');

// Add the close button
toastr.options.closeButton = true;
toastr.options.closeHtml = '<button><i class="fa fa-times"></i></button>';

// Show a progress bar
toastr.options.progressBar = true;

// Use the toastr class, instead of the toast. The toast class conflicts with the bootstrap 4 class.
toastr.options.toastClass = 'toastr';

// Register the toastr function.
global.toastr = toastr;