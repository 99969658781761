"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./loading-indicator.scss");
class LoadingIndicator {
    constructor(totalBatchCount, options = LoadingIndicator.defaultOptions) {
        this.totalBatchCount = totalBatchCount;
        this.totalProcessedCount = 0;
        this.element = document.createElement('div');
        this.progressBar = document.createElement('div');
        this.progressBarProgression = document.createElement('div');
        this.titleElement = document.createElement('h2');
        this.textElement = document.createElement('span');
        this.subTextElement = document.createElement('p');
        this.options = { ...LoadingIndicator.defaultOptions, ...options };
        this.initElement();
    }
    initElement() {
        const loadingIndicatorBody = document.createElement('div');
        loadingIndicatorBody.appendChild(this.titleElement);
        loadingIndicatorBody.appendChild(this.progressBar);
        loadingIndicatorBody.appendChild(this.textElement);
        loadingIndicatorBody.appendChild(this.subTextElement);
        this.element.classList.add('loading-indicator');
        loadingIndicatorBody.classList.add('body');
        this.progressBarProgression.classList.add('progression');
        this.progressBar.classList.add('progress-bar');
        this.progressBar.appendChild(this.progressBarProgression);
        this.element.appendChild(loadingIndicatorBody);
        document.body.appendChild(this.element);
        this.updateLoadingProgress(0);
        if (this.options.onStart) {
            this.options.onStart(this.element);
        }
    }
    updateLoadingProgress(processedCount) {
        this.totalProcessedCount += processedCount;
        const progressPercentage = Math.round((this.totalProcessedCount / this.totalBatchCount) * 100);
        this.progressBarProgression.style.width = `${progressPercentage}%`;
        this.titleElement.innerText = this.populateTextVariables(this.options.title);
        this.textElement.innerText = this.populateTextVariables(this.options.text);
        this.subTextElement.innerText = this.populateTextVariables(this.options.subText);
        if (this.options.onProgressUpdate) {
            this.options.onProgressUpdate(processedCount, this.totalBatchCount);
        }
        if (processedCount === this.totalBatchCount && this.options.automaticallyDestroyOnComplete !== false) {
            this.destroy();
        }
    }
    destroy() {
        if (this.options.onDestroy) {
            this.options.onDestroy();
        }
        this.element.remove();
    }
    populateTextVariables(text) {
        const progressPercentage = Math.round((this.totalProcessedCount / this.totalBatchCount) * 100);
        return text
            .replace('{count}', this.totalBatchCount.toString())
            .replace('{progressPercentage}', progressPercentage.toString())
            .replace('{processedCount}', this.totalProcessedCount.toString())
            .replace('{totalCount}', this.totalBatchCount.toString());
    }
}
exports.default = LoadingIndicator;
LoadingIndicator.defaultOptions = {
    title: 'Migrating {count} items',
    text: 'Bulk operation {progressPercentage}%',
    subText: 'Processed {processedCount} of {totalCount} items',
};
