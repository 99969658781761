import { ResizeSensor } from "css-element-queries";

function updateHeight(
    container,
    stickyClass,
    header
) {
    $(stickyClass, container).css({
        top: $(header).height()
    })
}

export const init = ({container = "#mainBody", stickyClass=".sticky-top", header=".fh-fixedHeader"} = {}) => {
    if ($(stickyClass, container).length >= 1) {
        updateHeight(container, stickyClass, header);

        new ResizeSensor($(header), () => {
            updateHeight(container, stickyClass, header);
        })
    }
}