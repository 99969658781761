import '../shared/index';
import '../../scss/theme/default/style.scss'
import '../../modules/search'

global.mapStyles = require('../../../templates/mapStyle.json');
global.getPinImage = function(google) {
    if (google && google.maps && google.maps.MarkerImage && (typeof google.maps.MarkerImage) === 'function') {
        return new google.maps.MarkerImage(
            require('../default/img/marker.png'),
            new google.maps.Size(32, 32),
            new google.maps.Point(0, 0),
            new google.maps.Point(16, 32)
        );
    } else {
        console.warn('Google, or google.maps, or google.maps.MarkerImage is not defined');
    }
};