import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import 'jsoneditor/dist/img/jsoneditor-icons.svg';

window.JSONEditor = function (element, options) {
    const resolved = {
        ...options
    };

    return new JSONEditor(
        element,
        resolved
    )
};