require('./style.scss');

function resize() {
    this.style.height = 'auto';
    this.style.height = [this.scrollHeight,this.style.paddingTop,this.style.paddingBottom, 2].map(Number).reduce((a, b) => a + b)+'px';
}

function delayedResize()
{
    const self = this;
    window.setTimeout(function() {
        resize.call(self);
    }, 0);
}

$(document).on('change', "textarea.auto-resize", resize).on('cut paste drop keydown', "textarea.auto-resize", delayedResize);