import React from 'react';
import { createRoot } from 'react-dom/client';
import SearchBox from '../../src/SearchBox';

if ($.fn.searchBox !== undefined) {
    console.error('SearchBox already defined');
} else {

    $.fn.searchBox = function () {
        $(this).each(function (index, element) {
            const root = createRoot(element);

            root.render((
                <SearchBox searchUrl={$(element).data('search-url')}
                           placeholder={$(element).data('placeholder')}
                           noResultText={$(element).data('no-result-text')}
                           defaultValue={$('input', element).val()}
                />
            ));
        });
        return $(this);
    };


    $(function () {
        $(".search_box_container").searchBox();
    })
}