import autosize from 'autosize';

function init() {
    autosize($("textarea.auto-resize"))
}

global.autosize = autosize;

export {
    init
}