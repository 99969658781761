import StickySidebar from "sticky-sidebar/dist/sticky-sidebar";
import { ResizeSensor } from "css-element-queries";

require('./style.scss');

global.ResizeSensor = ResizeSensor;

$(function() {
    const stickInParent = function() {
         new StickySidebar(
            "#mainSideBar .sidebar-content",
            {
                topSpacing: $(".navbar:first").height() + 10,
                bottomSpacing: 40,
            }
        )
    }
    const target = $("#mainSideBar .sidebar-content");
    if (target.length > 0) {
        stickInParent.call(target);
    }
})