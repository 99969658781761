
// Function to handle modals with different contents. Resets the modal to the original content upon closing the modal.
$.fn.preserveModalContent = function () {
    $(this).each(function (index, element) {
        $(element).on("show.bs.modal", function () {
            if ($(element).data('originalHtml') === undefined) {
                $(element).data("originalHtml", $(element).html());
            }
        });
        $(element).on('hidden.bs.modal', function() {
            if ($(element).data('originalHtml') !== undefined) {
                $(element).html($(element).data('originalHtml'));
            }
        })
    })
};

$(function () {
    // Automatically call the preserveModalContent on every modal with data-preserve-original-content.
    $(".modal[data-preserve-original-content]").preserveModalContent()
});