function init() {
    $("[data-content-uri]").each(function() {
        const dataset = this.dataset;
        const property = dataset.contentProperty;
        const template = dataset.contentTemplate;
        const element = this;

        fetchJSON(dataset.contentUri)
            .then(data => {
                let value = data;

                if (property) {
                    value = data[property];
                }
                if (value > 0) {
                    element.classList.add('show');
                }
                element.innerText = value;
            });
    });
}

async function fetchJSON(uri) {
    const response = await fetch(uri);

    return await response.json();
}

export {
    init
}
