import React, {Component} from 'react';

class Header extends Component {

    onClick = (e) => {
        e.preventDefault();
        this.props.onClick(this.props.title);
    };

    render() {
        const {title, active} = this.props;

        let activeClass = active ? 'active' : null;

        return (
            <li key="index"
                className={"search-filters-filter " + activeClass}>
                <a href="#"
                   className={"search-filters-button search-filters-button-text no-results"}
                   ref={title}
                   onClick={this.onClick}>
                    {title}
                </a>
            </li>
        )
    }
}

export default Header;