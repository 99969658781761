import React, {Component} from 'react';

class PageItem extends Component {
    onMouseOver = () => {
        this.props.mouseOver(this.props.keyIndex);
    };

    createRegEx = (searches) => {
        let regEx = null;
        searches.map((value, index) => {
            if (regEx == null) {
                regEx = '(' + this.escapeRegExp(value);
            } else {
                regEx += '|' + this.escapeRegExp(value);
            }
        });
        regEx += ')';
        return new RegExp(regEx, 'gi');
    };

    highlight = (input, searches) => {
        let parts = searches.length > 0 ? input.split(this.createRegEx(searches)) : [input];
        return <span>
            {
                parts.map((part, i) =>
                    <span key={i} className={searches.includes(part.toLowerCase()) ? 'highlight' : null}>
                        {part}
                    </span>
                )
            }
        </span>;
    };

    escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };

    keyDown = (event) => {
        if (event.keyCode == 13 && this.props.active) {
            this.refs.hyperlink.click();
        }
    };


    componentDidMount() {
        document.addEventListener("keydown", this.keyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDown, false);
    }

    render() {
        const {title, sub_title, icon, url, other_organization_name, title_of_location_risk_category, searches, active} = this.props;

        let className = active ?  'active' : null;
        let element_for_other_organization_name = other_organization_name
            ? <span className={"organisation-name badge badge-info"}>{other_organization_name}</span>
            : '';
        let element_for_location_risk_category = title_of_location_risk_category
            ? <small className="risk-category">({title_of_location_risk_category})</small>
            : '';

        return (
            <a ref="hyperlink" href={url} className={className} onMouseOver={this.onMouseOver}>
                <span className="result-icon">
                    <i className={"fa " + icon} aria-hidden="true"></i>
                </span>
                <span className="location-address">
                    <strong className="location-title">
                        {this.highlight(title, searches)}
                        {element_for_other_organization_name}
                    </strong>
                    <small className="location-subtitle">{this.highlight(sub_title, searches)}</small>
                    {element_for_location_risk_category}
                </span>
            </a>
        )
    }
}

export default PageItem;