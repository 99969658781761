import 'select2';

$.fn.select2.defaults.set("theme", "bootstrap");

function loadLanguage(lang = 'en') {
    let language = {};
    try {
        language = require('select2/src/js/select2/i18n/'+lang);
    } catch (e) {
        const result = /^(\w{2})[_-].*/.exec(lang);
        if (result !== null) {
            return loadLanguage(result[1]);
        }
    }
    return language;
}

$(function () {

    $.fn.select2.defaults.set("language", loadLanguage(document.documentElement.lang));
    $.fn.select2.defaults.set("width", "100%");

    $("select.select2:not(.noselect2)").each(function() {
        var parent = $(this).closest(".modal-body");
        if (parent.length === 0) {
            parent = $("body");
        }
        $(this).select2({
            dropdownParent: parent,
        })
    });
})
