export function init() {
    var head = global.document.getElementsByTagName('head')[0];

// Save the original method
    var insertBefore = head.insertBefore;

// Replace it!
    head.insertBefore = function (newElement, referenceElement) {

        if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {

            console.info('Prevented Roboto from loading!');
            return;
        }

        insertBefore.call(head, newElement, referenceElement);
    };
}