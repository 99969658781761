import 'intersection-observer';
import 'ric';
import 'whatwg-fetch';
import 'promise-polyfill';
import 'webstorage-polyfill';
import 'abortcontroller-polyfill';
import smoothscroll from 'smoothscroll-polyfill';

require('better-dom/dist/better-dom');

require('object-fit-images/dist/ofi');

smoothscroll.polyfill();

if (typeof Element.prototype.remove !== 'function') {
    Element.prototype.remove = function() {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}

if (typeof NodeList.prototype.remove !== 'function') {
    NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
        for (var i = this.length - 1; i >= 0; i--) {
            if (this[i] && this[i].parentNode) {
                this[i].parentNode.removeChild(this[i]);
            }
        }
    }
}
