export const init = () => {
    $(document).ready(initShadows);
    $(document).on('show.bs.modal', initShadows);

    function initShadows() {
        $('.table-responsive').each((i, el) => {
            buildShadows.apply(el);
        });
    }
}

var observeDOM = (function () {
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

    return function (obj, callback) {
        if (!obj || obj.nodeType !== 1) {
            return;
        }

        if (MutationObserver) {
            // define a new observer
            var mutationObserver = new MutationObserver(callback)

            // have the observer observe foo for changes in children
            mutationObserver.observe(obj, {childList: true, subtree: true})
            return mutationObserver
        }

        // browser support fallback
        else if (window.addEventListener) {
            obj.addEventListener('DOMNodeInserted', callback, false)
            obj.addEventListener('DOMNodeRemoved', callback, false)
        }
    }
}());

observeDOM(document.body, (changes) => {
    changes.forEach((change) => {
        change.addedNodes.forEach((addedNode) => {
            if ($(addedNode).hasClass('table-responsive')) {
                requestAnimationFrame(() => {
                    buildShadows.apply(addedNode);
                });
            }
        });
    });
});

$(document).on('init.dt', function (e, dtSettings) {
    if (e.namespace !== 'dt') {
        return;
    }
    const table = $(dtSettings.nTable).closest('.table-responsive')[0]
    if (table !== undefined) {
        buildShadows.apply(table);
    }
})

function buildShadows() {
    const $self = $(this);
    const $table = $($self.find('table').get(0));

    if ($self.parent().find('.table-responsive-shadow').length > 1) {
        return;
    }

    if ($self.find('.table-responsive-shadow').length > 1) {
        return;
    }

    const $before           = $('<div class="table-responsive-shadow shadow-before" />');
    const $after            = $('<div class="table-responsive-shadow shadow-after" />');
    const offset            = $self.offset();
    const offsetParent      = $self.offsetParent().offset();
    const tableMarginTop    = parseInt($self.find('>').css('marginTop'), 10);
    const tableMarginBottom = parseInt($self.find('>').css('marginBottom'), 10);
    const leftColumnOffset  = $table.hasClass('dtfc-has-left') ? $table.find('td').get(0).getBoundingClientRect().width : 0;

    $before.css({
        width   : '2rem',
        position: 'absolute',
        left    : offset.left - offsetParent.left - 1 + leftColumnOffset,
        top     : (offset.top - offsetParent.top) + tableMarginTop
    })
    $after.css({
        width   : '2rem',
        position: 'absolute',
        right   : $self.offsetParent()[0].clientWidth - ((offset.left - offsetParent.left) + $self[0].clientWidth),
        top     : (offset.top - offsetParent.top) + tableMarginTop
    });

    $self.append($before);
    $self.append($after);

    function calculateHeight() {
        const height = `${$self[0].clientHeight - tableMarginTop - tableMarginBottom}px`;

        $before.css({'height': height});
        $after.css({'height': height});

    }

    calculateHeight();

    const sensor = new ResizeSensor($self.find('table'), calculateHeight);


    let isScrolling = false;

    function setShadows(event) {
        if (!isScrolling) {
            window.requestAnimationFrame(function () {
                if (event.target.scrollLeft > 0) {
                    $before.addClass('show');
                } else {
                    $before.removeClass('show');
                }

                const diff = Math.abs(event.target.clientWidth - (event.target.scrollWidth - event.target.scrollLeft));
                if (diff > 1) {
                    $after.addClass('show');
                } else {
                    $after.removeClass('show');
                }
                isScrolling = false;
            });
            isScrolling = true;
        }
    }

    $self.on('scroll', setShadows);

    $(document).on('init.dt', function (e) {
        const newTable = $(e.target).closest('.table-responsive')[0];
        if (newTable !== undefined) {
            buildShadows.apply(newTable);
        }
    });

    $(window).on('resize', function () {
        sensor.detach(calculateHeight);
        $self.off('scroll');
        requestAnimationFrame(() => {
            $before.remove();
            $after.remove();
            buildShadows.call($self);
        })
    })
    requestAnimationFrame(() => {
        setShadows({target: $self[0]})
    })
}
