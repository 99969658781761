import '@fancyapps/fancybox';

require('@fancyapps/fancybox/src/css/core.css');
require('@fancyapps/fancybox/src/css/fullscreen.css');
require('@fancyapps/fancybox/src/css/slideshow.css');
require('@fancyapps/fancybox/src/css/thumbs.css');
require('@fancyapps/fancybox/src/css/share.css');
require('./style.scss');

$.fancybox.defaults.caption = function (instance, item) {
    const $caption = $(this).find('figcaption');

    if ($caption.length > 0) {
        return $caption.html();
    }
    return $(this).data('caption') || '';
}