export default function (options) {
    if (!$(this).is("form")) {
        console.error("This function should be called on a form");
        return;
    }
    const self = $(this);
    const opts = $.extend({
        href     : $(this).attr("action"),
        onSuccess: undefined,
        onFailure: undefined,
        method   : $(this).attr("method") || 'GET',
        container: self,
        submit   : undefined,
        indicator: '<i class="fa fa-sync fa-spin"></i>'
    }, options);

    // Because the data attributes are strings, check if they are 'false', 'null' or '0' and set it to null.
    if (opts.indicator === 'false' || opts.indicator === 'null' || opts.indicator === '0') {
        opts.indicator = null;
    }

    if (!(opts.indicator instanceof jQuery)) {
        opts.indicator = $(opts.indicator);
    }

    // Because the indicator needs the js-submit-indicator, add it if it doesn't exist.
    if (!opts.indicator.hasClass('js-submit-indicator')) {
        opts.indicator.addClass('js-submit-indicator');
    }

    if ($("select.select2:not(.noselect2)", this).select2) {
        $("select.select2:not(.noselect2)", this).each(function () {
            let parent = $(this).closest(".modal-body");
            if (parent.length === 0) {
                parent = $("body");
            }
            $(this).select2({
                dropdownParent: parent
            })
        });
    }
    $("a:not(.open-for-real,[data-fancybox],[target=_blank])", self).on('click', function (e) {
        const href = $(this).attr("href");
        if (href[0] === '#') {
            if (href !== '#' && $(this).attr('data-toggle') !== 'tab') {
                const $target = $(href);
                if ($target.length === 0) {
                    return;
                }
                $target[0].scrollIntoView({
                    behavior: 'smooth'
                });
            }
            return;
        }
        e.preventDefault();
        $.ajax(href, {
            method     : 'GET',
            processData: false,
            contentType: false,
            cache      : false
        }).then(function (data, status, jqXHR) {
            self.empty();
            $(opts.container).append(data);
            self.hide();
            const form = $("form:visible", opts.container);
            form.ajaxSubmit(opts);
            opts.onFailure(jqXHR, status, "");
            self.trigger("ajaxForm.submit.fail", [form, jqXHR, status, "formError"]);
            self.remove()
        }).catch(function (jqXHR) {
            if (jqXHR.status == 401) {
                location.reload();
            }
        });
    });

    self.on('submit', function (e) {
        console.log('Handling submit', e);
        if (e.isDefaultPrevented()) {
            return;
        }

        e.preventDefault();

        let isValid = true;
        $('input,textarea,select', self).filter('[required]:visible').each(function () {
            if (this.checkValidity && !this.checkValidity()) {
                isValid = false;
            }
        })

        if(!isValid){
            return;
        }


        const preSubmitEvent = $.Event('ajaxForm.preSubmit');
        self.trigger(preSubmitEvent);

        if (preSubmitEvent.result === false || preSubmitEvent.isDefaultPrevented()) {
            console.warn('Not submitting');
            return;
        }

        // Find the submit buttons, and add the optional external submit button.
        const buttons = $(":submit", self).add(opts.submit);

        // Returns the function to be called on the buttons.
        const changeButton = function (enabled) {
            return function (index, elem) {
                if (enabled) {
                    $(elem).removeAttr("disabled");
                    $(elem).removeClass("disabled");
                } else {
                    $(elem).attr("disabled", "disabled");
                    $(elem).addClass("disabled");
                }
                let indicator = $(".js-submit-indicator", elem);

                console.log(indicator);

                // If an indicator was found, or we must add an indicator
                if (indicator.length > 0 || opts.indicator) {
                    if (indicator.length == 0) {
                        // Add the cloned element, otherwise it would just be moved.
                        $(elem).append(opts.indicator.clone());
                        indicator = $(".js-submit-indicator", elem);
                    }
                    if (enabled) {
                        if (indicator.is(':visible')) {
                            indicator.hide(0);
                        }
                    } else if (indicator.is(':hidden')) {
                        indicator.show(0);
                    }
                }
            }
        };

        buttons.each(changeButton(false));

        const formData = new FormData($(this)[0]);

        self.trigger("ajaxForm.submit.start", [self]);

        if (self.data('extra')) {
            const base = new URL(window.location.href).origin;
            const url  = new URL(opts.href, base);
            url.searchParams.append('extra', self.data('extra'));
            opts.href = url.href;
        }

        $.ajax(opts.href, {
            method     : opts.method,
            data       : formData,
            processData: false,
            contentType: false,
            cache      : false
        }).then(function (data, status, jqXHR) {
            if (typeof data === 'string' && jqXHR.status != 201) {
                self.empty();
                $(opts.container).append(data);
                self.hide();
                const form = $("form:visible", opts.container);
                const newAction = form.attr('action');
                if (newAction) {
                    opts.href = newAction;
                }
                form.ajaxSubmit(opts);
                opts.onFailure(jqXHR, status, "");
                self.trigger("ajaxForm.submit.fail", [form, jqXHR, status, "formError"]);
                self.remove()
            } else {
                opts.onSuccess(data, jqXHR);
                self.trigger("ajaxForm.submit.success", [self, data, jqXHR]);
            }
        }).catch(function (jqXHR, status, error) {
            self.trigger("ajaxForm.submit.fail", [self, jqXHR, status, error]);
            opts.onFailure(jqXHR, status, error);
        }).always(function () {
            buttons.each(changeButton(true));
        });
    });
};