export default function () {
    $("[data-toggle='modal']").each(function () {
        const $this   = $(this);
        const $target = $($(this).data('target'));
        $target.on(
            'show.bs.modal',
            function () {
                $this.data('was-disabled', $this.hasClass('disabled'));
                $this.addClass('disabled');
            }
        ).on(
            'hide.bs.modal',
            function () {
                const wasDisabled = $this.data('was-disabled');
                if (wasDisabled !== undefined && !wasDisabled) {
                    $this.removeClass('disabled')
                }
            }
        )
    });
}