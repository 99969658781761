export default function (options) {
    $(this).each(function (index, element) {
        var self = $(element);
        if (self.data('remoteContent')) {
            return;
        }
        self.data('remoteContent', true);
        var opts = $.extend({
            href     : self.attr("href"),
            container: self.attr('data-container'),
            indicator: self.attr('data-indicator'),
            method   : 'GET'
        }, options);

        self.on('click', function (e) {
            e.preventDefault();
            $.ajax({
                method : opts.method,
                url    : opts.href,
                success: function (response) {
                    $(opts.container).html(response);
                    self.trigger('remoteContent.loaded');

                    $("[data-toggle='tooltip']", opts.container).tooltip();
                },
                fail   : function (jqXHR) {
                    $(opts.container).html(jqXHR.responseText);
                }
            })
        })
        self.trigger('remoteContent.bound');
    })
};