
export default function (options) {
    $(this).each(function (index, element) {
        const self = $(element);
        if (self.data('ajaxForm') === true) {
            console.warn('Form has been bound already');
            self.trigger('ajaxForm.bound');
            return;
        }
        self.data('ajaxForm.options', $.extend({
            href     : self.attr("href"),
            container: self.attr('data-container'),
            submit   : self.attr('data-submit'),
            onSuccess: undefined,
            onFailure: undefined,
            indicator: self.attr('data-indicator')
        }, options));

        self.data('ajaxForm', true);
        self.trigger('ajaxForm.bound');

        self.on('click', function (event) {
            const opts = self.data('ajaxForm.options');
            event.preventDefault();

            const onLoadSuccess = function (e, d) {
                if (e.isDefaultPrevented()) {
                    return;
                }
                let data = d;
                if (typeof d === 'object') {
                    if (d.body !== undefined) {
                        data = d.body;
                    }
                }

                $(opts.container).html(data);
                const form = $("form", opts.container);
                const url  = form.attr("action") || opts.href;
                form.ajaxSubmit({
                    href     : url,
                    container: opts.container,
                    onSuccess: function (data, jqXHR) {
                        self.trigger("ajaxForm.success", [data, jqXHR]);
                        if (typeof opts.onSuccess === 'function') {
                            opts.onSuccess(data);
                        }
                    },
                    onFailure: function (data) {
                        if (typeof opts.onFailure === 'function') {
                            opts.onFailure(data);
                        }
                    },
                    submit   : opts.submit,
                    indicator: opts.indicator
                });

                const fnFail = function (e, newForm) {
                    if (opts.submit) {
                        $(":submit", newForm).hide();
                    }
                    self.trigger("ajaxForm.fail", [newForm]);
                    newForm.on("ajaxForm.submit.fail", fnFail);
                };
                form.on("ajaxForm.submit.fail", fnFail);

                if (opts.submit) {
                    $(":submit", form).hide();
                    $(opts.submit).on('click', function (e) {
                        e.preventDefault();
                        const saveButtonElement = $(e.target);
                        const hasShowConfirmation = saveButtonElement.hasClass('showConfirmation');
                        const getConfirmationText = saveButtonElement.attr('data-confirmation-text');
                        if(hasShowConfirmation && getConfirmationText) {
                            if(!confirm(getConfirmationText)){
                                return false;
                            }
                        }

                        const f = $("form", opts.container);

                        if (this.dataset.extra) {
                            f.data('extra', this.dataset.extra);
                        }
                        let error = false;

                        $("select.select2[required]", f).each(function () {
                            if (!$(this).val()) {
                                error = true;
                                $(this).parent().addClass('has-error');
                                $(this).parent().popover({
                                    content  : $(this).data('required-content'),
                                    placement: 'bottom',
                                    container: f
                                }).popover('show');

                                $(this).on('change', function () {
                                    $(this).parent().popover('hide');
                                    $(this).parent().removeClass('has-error');
                                })
                            }
                        });

                        if (error) {
                            e.preventDefault();
                            return false;
                        }

                        console.log('Submitting');
                        console.log(e.target);
                        if($(e.target).hasClass('hasQr')) {
                            $(e.target).attr('data-qr', 1);
                        }

                        if (f.checkValidity && f.checkValidity()) {
                            f.submit();
                        } else {
                            if ($(":submit", f).length === 0) {
                                $(f).append("<button type='submit' class='d-none'></button>");
                            }
                            $(":submit", f).click();
                        }
                    });
                }

                self.trigger('ajaxForm.bound', [form]);
            };

            self.on('ajaxForm.load.success', onLoadSuccess);
            self.trigger('ajaxForm.load.start');
            if (self.data('target')) {
                $(self.data('target')).find('.modal-footer .btn').addClass('disabled');
            }
            $.ajax(opts.href)
                .then(function (d) {
                    self.trigger('ajaxForm.load.success', [d]);
                })
                .catch(function (jqXHR, status, error) {
                    if(jqXHR.status == 401){
                        location.reload();
                    }
                    self.trigger("ajaxForm.load.fail", [jqXHR, status, error]);
                })
                .always( function(d) {
                    if (self.data('target')) {
                        $(self.data('target')).find('.modal-footer .btn').removeClass('disabled');
                    }
                    self.off('ajaxForm.load.success', onLoadSuccess);
                })
            ;
        })
    });

    return $(this);
};