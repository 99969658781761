class TokenField {
    static defaultOptions = {
        createOnTab: false,
        allowNewItems: false,
        sanitizer: undefined,
    }

    initialized = false;
    value       = [];

    options;

    element;
    container;

    input;

    observer;

    constructor(
        element,
        container,
        options = TokenField.defaultOptions
    ) {
        this.element                  = element;
        this.element[0].dataset.value = this.element.val();
        this.options                  = $.extend({}, TokenField.defaultOptions, options);
        this.source                   = options.items;
        this.observer                 = new MutationObserver(this.observe)
        this.observer.observe(this.element[0], {
            attributes     : true,
            attributeFilter: ['disabled']
        });
    }

    init() {
        if (this.initialized) {
            return;
        }

        this.input = $('<input type="text" />');
        this.element.wrap($('<div class="tokenfield-container" />'));

        this.element.addClass('d-none');
        this.container = this.element.parent();
        this.container.append(this.input);
        this.container.on('click', this.onContainerClick);
        this.input.autocomplete({
            autoFocus: true,
            source   : this.source,
            select   : (event, item) => {
                this.element.trigger('tokenfield:createtoken', [item.item]);
                this.input.val('');
                event.preventDefault();
            },
            focus    : (event, ui) => {
                event.preventDefault();
            },
            minLength: 0,
            delay    : 0
        })
        this.element.on('tokenfield:createtoken', this.onTokenCreate);
        this.element.on('tokenfield.removetoken', this.onTokenRemove);
        this.input.on('focus', () => {
            this.container.addClass('focussed');
            this.input.autocomplete('search', this.input.val() || '') // Show the elements
        });
        this.input.on('blur', () => {
            this.container.removeClass('focussed');
        })
        if (this.options.createOnTab) {
            this.input.on('keydown', (e) => {
                if (e.isDefaultPrevented()) {
                    return;
                }
                if (e.which === 9 && this.input.val() !== '') {
                    this.element.trigger('tokenfield:createtoken', [this.input.val()]);
                    this.input.val('');
                    e.preventDefault();
                    return false;
                }
            })
        }
        try {
            const val = JSON.parse(this.element.val());

            this.setTokens(val);
        } catch (e) {
            console.warn('Could not load the initial tokens', e);
        }

        this.input.autocomplete('widget').insertAfter(this.container);
        this.handleDisability();

        this.update();
    }

    observe = (mutations) => {
        for (const mutation of mutations) {
            if (mutation.attributeName !== 'disabled') {
                continue;
            }
            this.handleDisability();
        }
    }

    handleDisability = () => {
        this.disabled = this.element.is(':disabled');
        if (this.disabled) {
            this.container.addClass('disabled');
            this.input.addClass('disabled').attr('disabled', 'disabled');
        } else {
            this.container.removeClass('disabled');
            this.input.removeClass('disabled').removeAttr('disabled');
        }

    }

    onContainerClick = (event, ...args) => {
        if (this.disabled) {
            return;
        }

        if (event.target.classList.contains('token')) {
            const $token = $(event.target);
            this.element.trigger('tokenfield.removetoken', [$token, $token.data('token-index'), this.value[$token.data('token-index')]]);
            return;
        }

        this.input.focus();
    }

    destroy() {

    }

    update() {
        this.container.find('.token').remove();

        [...this.value].reverse().forEach((item, index) => {
            const token = this.renderToken(item, this.value.length - index - 1);
            this.container.prepend(token);
        });

        this.element.val(JSON.stringify(this.value));
    }

    setTokens(tokens) {
        this.value = [];
        tokens.forEach(this.addToken);
    }

    addToken = (item) => {
        this.value.push(item);
    }

    getTokens() {
        return [...this.value];
    }

    onTokenRemove = (event, element, tokenIndex) => {
        if (event.isDefaultPrevented()) {
            return;
        }

        this.value.splice(tokenIndex, 1);

        this.element.trigger('tokenfield.removedtoken');

        this.update();
        this.input.focus();
    }

    renderToken({label, value}, index) {
        const token = $('<div class="token">' + label + '</div>');
        token.data('token-index', index);

        return token;
    }

    onTokenCreate = (e, item) => {
        if (e.isDefaultPrevented()) {
            return;
        }
        if (this.options.sanitizer !== undefined) {
            const sanitized = this.options.sanitizer(e, item);
            if (sanitized === undefined) {
                return;
            }
            this.addToken(sanitized);
        } else {
            this.addToken(item);
        }

        this.element.trigger('tokenfield.createdtoken');

        this.update();
    }
}

export default TokenField;
