import React, {Component} from 'react';
import Header from "../Header";

class Headers extends Component {
    render() {
        const {headers, used, onClick} = this.props;

        const filters = headers.map((value, index) => <Header key={index} title={value} active={used == value} onClick={onClick}/>);

        return (
            <ul className="search-filters">
                {filters}
            </ul>
        )
    }
}

export default Headers;