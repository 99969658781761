import React from 'react';
import Results from './Results';
import SearchButton from './SearchButton';

export const makeCancelableFunction = (fn) => {
    let hasCanceled = false;

    return {
        promise: (val) => new Promise((resolve, reject) => {
            if (!hasCanceled) {
                fn(val);
                resolve(val);
            }
            fn = null;
        }),
        cancel() {
            hasCanceled = true;
        }
    };
};

class SearchBox extends React.Component {

    searchTimeout = null;
    ignoreBlur    = false;
    currentFetch  = null;

    constructor(props) {
        super(props);

        this.state = {
            searching     : false,
            searchUrl     : props.searchUrl,
            displayResults: true,
            headerUsed    : null,
            results       : [],
        };
    }

    onFocus = () => {
        this.setState({
            displayResults: true
        });
    };

    onBlur = () => {
        if (!this.ignoreBlur) {
            this.setState({
                displayResults: false
            });
        } else {
            this.searchInput.focus();
        }
    };

    onMouseOver = () => {
        this.ignoreBlur = true;
    };

    onMouseOut = () => {
        this.ignoreBlur = false;
    };

    searchChanged = (e) => {
        if (this.searchTimeout !== null) {
            clearTimeout(this.searchTimeout);
        }
        let searchValue    = e.target.value;
        this.searchTimeout = setTimeout(() => {
            this.search(searchValue);
        }, 200);

        this.setState({
            displayResults: true
        });
    };

    search = (searchValue) => {
        if (this.currentFetch != null) {
            this.currentFetch.cancel();
            this.currentFetch = null;
        }

        if (searchValue.trim() == '') {
            this.setState({
                searching     : false,
                displayResults: false,
                results       : []
            });

            return;
        }

        this.setState({
            searching: true
        });

        let currentFetch = makeCancelableFunction((results) => {
            let newState = {
                results: results
            };

            newState.searching = false;
            this.setState(newState);
        });

        fetch(this.state.searchUrl + "?q=" + encodeURIComponent(searchValue)).then(res => res.json()).then((results) => {
            currentFetch.promise(results);
        });
    };

    setHeaderUsed = (headerUsed) => {
        this.setState({
            headerUsed: headerUsed
        });
    };

    escFunction = (event) => {
        this.ignoreBlur = false;
        if (event.keyCode === 27) {
            this.searchInput.blur();
        }
    };

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    render() {
        let results    = null;
        let extraClass = '';

        if (this.state.results.length !== 0) {
            results    = <Results
                results={this.state.results}
                headerUsed={this.state.headerUsed}
                setHeaderUsed={this.setHeaderUsed}
                onMouseOut={this.onMouseOut}
                onMouseOver={this.onMouseOver}
                noResultText={this.props.noResultText}
            />;
            if (this.state.displayResults) {
                extraClass = 'active';
            }
        }
        let focus = false;
        if (this.searchInput !== null) {
            focus = this.searchInput === document.activeElement;
        }

        return (
            <div className={`search_box ${extraClass}`.trim()}>
                <div className={`search ${focus ? 'focussed' : ''}`.trim()}>
                    <SearchButton searching={this.state.searching}/>
                    <input
                        ref={(input) => this.searchInput = input}
                        type="text"
                        defaultValue={this.props.defaultValue}
                        className="searchTerm"
                        placeholder={this.props.placeholder}
                        onChange={this.searchChanged}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                    />
                </div>
                <div className={`search_box-result-container customFade${this.state.displayResults ? ' customShow' : ''}`}>
                    {results}
                </div>
            </div>
        )
    }
}

export default SearchBox;