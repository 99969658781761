import React, {Component} from 'react';

class SearchButton extends Component {
    render() {
        const {searching} = this.props;

        let displaySearchButton;

        if (searching) {
            displaySearchButton = <i className="fa fa-fw fa-refresh fa-spin" /> ;
        } else {
            displaySearchButton = <i className="fa fa-fw fa-search" />;
        }


        return (
            <div className="searchButton">
                {displaySearchButton}
            </div>
        )
    }
}

export default SearchButton;